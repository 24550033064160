

export default function SvgLogo({theme}: {theme: string}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="160px" height="53px" viewBox="0 0 90000 30000">
            <path fill="#FC0" d="m4189.2 21566.9-.3-13344.9 11557.5-6672.3V5998l-8002.6 4620.1v4448.4l8002.6-4620.2v4448.3z"/><path fill="#C93" d="m27303.3 21567.2-11556.9 6672-3852.5-2224.3 8002.6-4620.2-3852.5-2224.3-8002.3 4620.5-3852.5-2224.3 11557.2-6672z"/>
            <path fill="#FC0" d="M15746.4 1549.7 27303.6 8222v4448.5L15746.4 5998V1549.7zm11557.2 15569.1v4447.8l-11557.2-6672v-4448.3l11557.2 6672.5z"/>
            <path id="svglogo" fill={theme === "dark" ? "#fff" : "#000"} d="M37948.6 11436.9h2440.3c-228.2-2703.6-1843.4-3599-4283.6-3599-2773.9 0-4318.8 1316.7-4318.8 3844.8 0 2791.4 1615.1 3546.3 3142.5 3739.4 1439.6 193.1 3335.6 193.1 3335.6 1790.7 0 1527.3-667.1 2141.8-2124.2 2141.8-1544.9 0-2001.4-561.8-2054.1-1597.6h-2440.2c0 2176.9 1070.9 3809.6 4441.6 3809.6 3247.8 0 4599.6-1316.6 4599.6-4441.6 0-2703.6-1492.2-3335.6-3054.7-3581.4-2054-316-3405.8-175.6-3405.8-1878.5 0-1281.6 544.2-1702.9 1878.5-1702.9 1176.2 0 1773.1 333.6 1843.3 1474.7zm7198-3511.2h-2440.3v13518.1h2440.3V7925.7zm6811.7 8005.5h2299.8v1316.7c0 1334.2-614.5 2071.6-2370 2071.6-1755.6 0-2089.2-737.4-2089.2-2018.9v-5301.9c0-1228.9 333.6-2018.9 2089.2-2018.9 1878.4 0 2194.4 632 2194.4 1896h2422.8c0-2896.7-1351.8-4037.9-4617.2-4037.9-3265.4 0-4511.9 1281.6-4511.9 4037.9v5424.8c0 2914.2 1299.1 4195.8 4511.9 4195.8 3212.7 0 4617.2-1281.6 4617.2-4248.5v-3511.2h-4547v2194.5zm15221-8005.5h-2405.2v3195.2l263.3 5705.7-3967.6-8900.9h-2405.1v13500.5h2405.1v-3441l-210.7-5126.3 3967.7 8567.3h2352.5V7925.7zm7513.9 0H72288l-3739.4 13500.5h2492.9l702.3-2387.6h3458.5l684.7 2387.6h2492.9L74693.2 7925.7zm-2352.5 8742.9 1123.6-5003.5 1123.5 5003.5h-2247.1zm15080.5 2545.6h-5214.1v-11306h-2440.3v13518h7654.4v-2212z"/>
        </svg>
        )
}